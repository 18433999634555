import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react';
import { GoogleMap, Marker, Polyline, DrawingManager } from '@react-google-maps/api';
import { mapStyles, getColor, getLatLng } from 'utils/mapsUtils';
import RetailerMarker from 'components/RetailerMarker';
import { actionTypes } from 'delivery/planning/planningReducer';
import { isVal } from 'utils/utils';
import Maps from 'components/Maps';
import { useKeyPress } from 'utils/customHooks';

function MapsPolishing({
  retailers,
  picklists,
  state: {
    branch,
    retailerInfoWindow,
    salesView,
    routesView,
    vehicleTypes,
    selectedRetailers,
    selectedPicklists,
    selectedChannels,
    highlightFilters,
    salesmen,
  },
  dispatch,
}) {
  const multiSelectArea = useRef();
  const mapRef = useRef();
  const shiftPressed = useKeyPress('Shift');
  const escPressed = useKeyPress('Escape');
  const [mapTypeId, setMapTypeId] = useState('roadmap');

  const retailersArray = useMemo(() => Object.values(retailers), [retailers]);
  const google = useMemo(() => window.google, []);
  const bounds = useMemo(() => {
    let bounds = new google.maps.LatLngBounds();
    retailersArray
      .filter(({ latitude, longitude }) => latitude && longitude)
      .forEach((retailer) => bounds.extend(getLatLng(retailer)));
    bounds.extend({ lat: branch.latitude, lng: branch.longitude });
    return bounds;
  }, [google.maps.LatLngBounds, branch]); // eslint-disable-line react-hooks/exhaustive-deps
  // as the list of latLngs of retailers r not gonna change

  const clearMultiSelectArea = useCallback(() => {
    if (multiSelectArea.current !== undefined) {
      multiSelectArea.current.setMap(null);
    }
  }, []);

  useEffect(() => {
    if (!selectedRetailers.length) clearMultiSelectArea();
  }, [clearMultiSelectArea, selectedRetailers]);

  useEffect(() => {
    if (escPressed) {
      clearMultiSelectArea();
      dispatch({ type: actionTypes.CLEAR_SELECTED_RETAILERS });
    }
  }, [escPressed, clearMultiSelectArea, dispatch]);

  const retailersSelectable = useCallback(
    (r) => r.longitude && r.latitude && (!isVal(r.index) || selectedPicklists.includes(r.index)),
    [selectedPicklists]
  );

  const onRectangleComplete = useCallback(
    (rect) => {
      dispatch({
        type: actionTypes.RETAILERS_SELECTED,
        retailerIds: retailersArray.filter(
          (r) => retailersSelectable(r) && rect.getBounds().contains(getLatLng(r))
        ),
      });
      clearMultiSelectArea();
      multiSelectArea.current = rect;
    },
    [dispatch, clearMultiSelectArea, retailersArray, retailersSelectable]
  );

  const onDragEnd = useCallback(
    (retailer, e) => {
      dispatch({
        type: actionTypes.RETAILER_DRAGGED,
        retailer,
        latLng: { lat: e.latLng.lat(), lng: e.latLng.lng() },
      });
    },
    [dispatch]
  );

  const renderRetailers = () => {
    const retailerMarkers = retailersArray
      .filter(({ latitude, longitude }) => latitude && longitude)
      .map((retailer) => {
        return (
          <RetailerMarker
            key={retailer.id}
            salesView={salesView}
            colorIndex={Object.values(picklists).findIndex((ou) =>
              ou.retailerIds?.includes(retailer.id)
            )}
            retailer={retailer}
            salesmen={salesmen}
            onDragEnd={onDragEnd}
            vehicleTypes={vehicleTypes}
            visible={isVal(retailer.index) ? selectedPicklists.includes(retailer.index) : true}
            dispatch={dispatch}
            selectedChannels={selectedChannels}
            highlightFilters={highlightFilters}
            retailerInfoWindow={
              retailerInfoWindow && retailerInfoWindow.id === retailer.id
                ? retailerInfoWindow
                : false
            }
          />
        );
      });
    return retailerMarkers;
  };

  const renderPolyline = () => {
    const polylines = Object.values(picklists)
      .filter(({ retailerIds }) => retailerIds && retailerIds.length)
      .map(({ index, retailerIds, route }, arrayIndex) => {
        let path;
        if (routesView) {
          path = route
            ? google.maps.geometry.encoding.decodePath(route)
            : new google.maps.MVCArray();
        } else {
          path = new google.maps.MVCArray();
          path.push(new google.maps.LatLng(branch.latitude, branch.longitude));
          retailerIds &&
            retailerIds.forEach((retailerId) =>
              path.push(
                new google.maps.LatLng(
                  retailers[retailerId].latitude,
                  retailers[retailerId].longitude
                )
              )
            );
        }
        return (
          <Polyline
            key={index}
            visible={selectedPicklists.includes(index)}
            options={{
              strokeColor: getColor(arrayIndex).fillColor,
              strokeOpacity: 1,
              strokeWeight: 0.6,
            }}
            path={path}
          />
        );
      });
    return polylines;
  };

  return (
    <GoogleMap
      id="plan-polishing"
      mapContainerStyle={{
        height: '600px',
        width: '100%',
      }}
      onLoad={(map) => {
        mapRef.current = map;
        map.fitBounds(bounds);
        map.mapTypes.set(
          'OSM',
          new google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {
              return (
                'https://tile.openstreetmap.org/' + zoom + '/' + coord.x + '/' + coord.y + '.png'
              );
            },
            tileSize: new google.maps.Size(256, 256),
            name: 'OSM',
            maxZoom: 18,
          })
        );
      }}
      bounds={bounds}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        draggable: shiftPressed ? false : true,
        mapTypeId,
        mapTypeControlOptions: {
          // mapTypeIds: ['roadmap', 'satellite', 'OSM'],
          mapTypeIds: ['roadmap', 'satellite'],
        },
      }}
      onMapTypeIdChanged={() => {
        if (mapRef.current) setMapTypeId(mapRef.current.mapTypeId);
      }}
      onClick={() => {
        retailerInfoWindow &&
          dispatch({
            type: actionTypes.CLOSE_RETAILER_INFO_WINDOW,
          });
      }}
    >
      <DrawingManager
        drawingMode={shiftPressed ? google.maps.drawing.OverlayType.RECTANGLE : null}
        onRectangleComplete={onRectangleComplete}
        options={{
          drawingControl: false,
          rectangleOptions: {
            strokeWeight: 1,
            strokeOpacity: 1,
            fillOpacity: 0.2,
            clickable: false,
            strokeColor: '#3399FF',
            fillColor: '#3399FF',
          },
        }}
      />
      <Marker position={{ lat: branch.latitude, lng: branch.longitude }} />
      {renderRetailers()}
      {renderPolyline()}
    </GoogleMap>
  );
}

export default function PlanPolishingMap(props) {
  return <Maps render={() => <MapsPolishing {...props} />} />;
}
