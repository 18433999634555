import ConfirmationDialog from 'components/ConfirmationDialog';
import Container from 'components/Container';
import { useGState } from 'state/store';
import { useLocation } from '@reach/router';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/feedbacks/Dialog';
import Button from 'components/inputs/ButtonM';
import DatePicker from 'components/inputs/DatePicker';
import IconButton from 'components/inputs/IconButton';
import Select from 'controlTower/components/Select';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import DownloadIcon from 'svgs/Download';
import { useBranchId } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { byId, formatDate, formatDateTime, formatDateHumanReadable } from 'utils/utils';
import DeliveryOrderDetails from './DeliveryOrderDetails';
import InvoiceUpdateDetails from './InvoiceUpdateDetails';
import Uploader from './Uploader';

const style = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: 'inherit',
  cursor: 'pointer',
};

// const dialog = {
//   title: 'Subsequent Order Plan Type?',
//   yes: 'Top-up Plan',
//   no: 'New Plan',
//   onYes: () => '&topUp=true',
//   onNo: () => '',
// };

function DeliveryOrder() {
  const branchId = useBranchId();
  const location = useLocation();
  const [date, setDate] = useState(formatDate(new Date()));
  const [orderUploads, setOrderUploads] = useState([]);
  const [orderUpload, setOrderUpload] = useState();
  const [cancelConfirmationDialog, setCancelConfirmationDialog] = useState(false);
  const [orderUploadWarningMsg, setOrderUploadWarningMsg] = useState('');
  const orderUploadWarningDialog = useRef();
  const holidayNoticeDialog = useRef();
  const permissions = useGState((s) => s.permission);
  const userHasOrderTrayPermission = permissions.some((p) => p && p.code === 'tms.orderTray');

  const { refetch } = useQuery(
    branchId && branchId !== -1 && ['orderUploads', branchId, date],
    () => fetch(`/v2/delivery/plan/status?branchId=${branchId}&date=${date}`),
    {
      onSuccess: (data) => {
        setOrderUploads(data?.orderUploads || []);
      },
    }
  );
  useQuery(
    branchId && branchId !== -1 && ['holiday', branchId, date],
    () => fetch(`/v2/delivery/plan/isHoliday?nodeId=${branchId}&date=${date}`),
    {
      onSuccess: (resp) => {
        if (resp.holiday) {
          holidayNoticeDialog.current.openDialog();
        }
      },
    }
  );
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const planDateParam = queryParams.get('planDate');

    if (planDateParam) {
      const parsedDate = new Date(planDateParam);
      setDate(formatDate(parsedDate));
    }
  }, [location.search]);
  const [cancelOrderUpload] = useMutation(
    (orderUploadId) =>
      fetch({
        url: `/v2/delivery/plan/cancel?orderUploadId=${orderUploadId}`,
        method: 'POST',
      }),
    {
      onSuccess: () => {
        setCancelConfirmationDialog(false);
        refetch();
      },
    }
  );

  const orderUploadsList = useMemo(() => {
    const options = orderUploads.map((ou, index) => ({
      value: ou.id,
      label: `${!userHasOrderTrayPermission ? 'Order' : 'Plan'} ${index + 1}, ${formatDateTime(new Date(ou.createdAt))}`,
    }));
    setOrderUpload(options[options.length - 1]);
    return options;
  }, [orderUploads]);

  if (!branchId || branchId === -1)
    return (
      <p class="m-10">
        This page is only available for a Branch. Please select a branch from the branch dropdown
      </p>
    );

  return (
    <Container>
      <ConfirmationDialog
        title="Info"
        desc={orderUploadWarningMsg}
        ref={orderUploadWarningDialog}
        no=""
        yes="OK"
      />
      <ConfirmationDialog
        title="Info"
        desc={`The site is not operational on ${formatDateHumanReadable(new Date(date))}`}
        ref={holidayNoticeDialog}
        no=""
        yes="OK"
      />
      <Dialog open={cancelConfirmationDialog} onClose={() => setCancelConfirmationDialog(false)}>
        <DialogTitle>Are you sure you want to proceed?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will lose the whole upload details. You will lose all the delivery states marked for
            the order upload.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setCancelConfirmationDialog(false)}>
            No
          </Button>
          <Button onClick={() => cancelOrderUpload(orderUpload?.value)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div class="py-12">
      <h1 className="text-3xl font-semibold text-primary">Planning</h1>
            <div class={`grid ${!userHasOrderTrayPermission ? 'grid-cols-3' : 'grid-cols-2'} gap-4`}>
            <div class={`col-span-2 bg-white rounded-lg shadow-sm p-10 ${!userHasOrderTrayPermission ? '' : '-mr-4'}`}>
            <p class="text-xs font-medium text-secondary ml-1">Plan Date</p>
            <div class="flex justify-between items-baseline">
              <div class="h-14 mt-2">
                <DatePicker
                  value={date}
                  onChange={(selectedDate) => {
                    if (selectedDate && !isNaN(selectedDate)) setDate(formatDate(selectedDate));
                  }}
                  class="w-64 text-sm font-medium"
                  inputFormat="d LLLL yyyy"
                />
              </div>
              <div class="h-14">
                <Button onClick={() => window.open(`/dl/planProfile/${branchId}`)}>
                  Edit Plan Profile
                </Button>
              </div>
            </div>
            {orderUploads.length ? (
              <>
                <p class="text-xs font-medium text-secondary ml-1 mt-10">{!userHasOrderTrayPermission ? 'Order Uploads' : 'Order Plans'}</p>
                <div class="flex justify-start items-center">
                  <div class="mt-2 w-80">
                    <Select
                      options={orderUploadsList}
                      value={orderUpload}
                      onChange={(val) => setOrderUpload(val)}
                    />
                  </div>
                  <div class="ml-4 bg-green-600 rounded-md">
                    <IconButton
                      color="primary"
                      href={`/api/delivery/plan/orderUpload?orderUploadId=${orderUpload?.value}`}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </div>
                  <div class="ml-4">
                    <Button
                      // disabled={orderUpload?.value !== orderUploads[orderUploads.length - 1]?.id}
                      onClick={() => setCancelConfirmationDialog(true)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
          </div>

          {!userHasOrderTrayPermission && <div class="col-span-1 bg-white rounded-lg shadow-sm p-10 pb-5 flex-col text-center">
            <Uploader
              url={`v2/delivery/plan/order?branchId=${branchId}&date=${date}&lineItems=true`}
              // dialog={orderUploads.length >= 1 ? dialog : undefined}
              onSuccess={(resp) => {
                if (resp?.message) {
                  orderUploadWarningDialog.current.openDialog();
                  setOrderUploadWarningMsg(resp.message);
                }
                refetch();
              }}
              style={style}
            />
            <Button
              variant="text"
              component="a"
              href={`/api/delivery/plan/order/template?branchId=${branchId}`}
            >
              Download Sample
            </Button>
          </div>}
          {orderUpload ? (
            <>
              <div class="col-span-3 bg-white rounded-lg shadow-sm p-10">
                <DeliveryOrderDetails data={byId(orderUploads, orderUpload.value)} />
              </div>
              <div class="col-span-3 bg-white rounded-lg shadow-sm p-10">
                <InvoiceUpdateDetails uploaderId={orderUpload.value} refetch={refetch} />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Container>
  );
}

export default DeliveryOrder;
